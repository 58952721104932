// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-get-involved-js": () => import("./../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-programs-js": () => import("./../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-stories-charles-garrison-js": () => import("./../src/pages/stories/charles-garrison.js" /* webpackChunkName: "component---src-pages-stories-charles-garrison-js" */),
  "component---src-pages-stories-harry-and-harriet-js": () => import("./../src/pages/stories/harry-and-harriet.js" /* webpackChunkName: "component---src-pages-stories-harry-and-harriet-js" */),
  "component---src-pages-stories-joseph-thompson-js": () => import("./../src/pages/stories/joseph-thompson.js" /* webpackChunkName: "component---src-pages-stories-joseph-thompson-js" */),
  "component---src-pages-stories-js": () => import("./../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-stories-norman-whittredge-js": () => import("./../src/pages/stories/norman-whittredge.js" /* webpackChunkName: "component---src-pages-stories-norman-whittredge-js" */),
  "component---src-pages-stories-theodore-thompson-js": () => import("./../src/pages/stories/theodore-thompson.js" /* webpackChunkName: "component---src-pages-stories-theodore-thompson-js" */)
}

